import React from 'react'
import { Select } from '@sharecover-co/ui'
import { useFormikContext } from 'formik'

const YearSelector = (props) => {
    const formik = useFormikContext()
    return (
        <Select
            required
            name="finacialYear"
            id="finacialYear"
            label="Finacial Year"
            choices={{
                '2020-2021': '2020-2021',
                '2021-2022': '2021-2022',
                '2022-2023': '2022-2023',
                '2023-2024': '2023-2024',
            }}
            style={{ maxWidth: '400px' }}
            onChange={(e) => {
                formik.handleChange(e)
                props.onYearChange(e.target.value)
            }}
        />
    )
}

export default YearSelector
